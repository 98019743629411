<template>
  <div class="video">
      <div class="text-title">
        <i>视频制作</i>
        <p>
          三维动画是艺术和技术紧密结合的最终呈现，从简单的艺术品展示、动/静态人物模型，到复杂的建筑场景、虚拟城市、角色动画等
        </p>
      </div>
      <div class="make">
          <div class="make-left">
              <video-player
            class="video-player vjs-custom-skin size-box"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          />
          </div>
          <div class="make-right">
              <h4>动画设计与视频制作</h4>
              <p>三维动画是艺术和技术紧密结合的最终呈现，从简单的艺术品展示、动/静态人物模型，到复杂的建筑场景、虚拟城市、角色动画等，均利用三维建模技术实现。昱安拥有专业的动画制作团队，在策划、脚本和影片创意方面具有丰富的经验，能在准确把控和分析剧本的同时，完成镜头画面的出色设计，制作富有节奏感和连贯感的动态分镜，充分展现制作工艺的精良和艺术的张力，运用独特的创意构架，优美的镜头画面，流畅的影视语言，结合三维动画、虚拟现实技术，打造文博行业视觉领域的极致体验。</p>
          </div>
      </div>
  </div>
</template>

<script>
import { getColumnContent } from "../api/index.js";
import store from "@/store";
export default {
  data () {
    return {
      baseIP: process.env.VUE_APP_BASE_URL,
      imageShowAPI: "/yuan/files/getFileStream/",
      VideoData:{},
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4", // url地址  http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4
          },
        ],
        poster: '', // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    }
  },
  created () {
      // 视频制作数据
    getColumnContent({ optionType: "7" }).then((res) => {
      console.log(res,'1111')
      if (res.code === 0 && res.data.content.length > 0 && res.data.content[0].columnsContentFiles[0] != undefined) {
          this.playerOptions.sources[0].src = ''
          this.playerOptions.sources[0].src = this.baseIP + this.imageShowAPI + res.data.content[0].columnsContentFiles[0].files.uri;
          this.VideoData = res.data.content[0]
          this.playerOptions.poster = this.VideoData.contentImageId? this.baseIP+this.imageShowAPI+this.VideoData.contentImage.waterMarkUri:''
        }
    });
  }
}
</script>

<style lang="scss" scoped>
.text-title {
    line-height: 120px;
    margin: 0 auto;
    padding-top: 90px;
    margin-bottom: 72px;
    box-sizing: border-box;
    i {
      display: block;
      font-style: normal;
      height: 40px;
      line-height: 40px;
      font-size: 34px;
      color: #333333;
    }
    p {
      line-height: 40px;
      font-size: 16px;
      color: #999999;
      margin: 10px 0;
    }
  }
.make{
    height: 443px;
    margin-bottom: 144px;
    .make-left{
        width: 738px;
        height: 430px;
        float: left;
        overflow: hidden;
        padding-top: 12px;
        .size-box {
        width: 738px;
      }
   
      /deep/ .video-js {
      background-color: #f1f1f1;
      margin-top: 10px;
    }
   
    /deep/ .vjs-poster{
      background-color: #f1f1f1;
    }
        img{
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all 0.9s;
        }
        img:hover{
          transform: scale(1.2);
        }
    }
    .make-right{
        width: 620px;
        height: 419px;
        float: left;
        box-sizing: border-box;
        padding: 20px;
        box-shadow: 0px 5px 10px 0px rgba(56, 56, 56, 0.13);
        margin-top: 20px;
        h4{
            height: 40px;
            line-height: 40px;
            text-align: left;
            font-weight: normal;
            color: #333333;
            font-size: 24px;
            margin-top: 33px;
            
        }
        p{
            margin-top: 20px;
            line-height: 40px;
            font-size: 18px;
            color: #747474;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            overflow: hidden;
        }
    }
}
</style>